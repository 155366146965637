import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, DatePicker, Popover } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  Card,
  Col,
  GeoJsonToSvg,
  Modal,
  Row,
  Tooltip
} from 'syngenta-digital-cropwise-react-ui-kit';

import { ArchivedVersion, checkTemporalGap } from 'app/fields/archiving';
import isEmpty from 'lodash/isEmpty';
import { getLocale } from 'utils/getDatePickerLocale';
import more_icon from '../../public/more_icon.png';
import { AppConstants } from '../../utils/app.constants';
import { getAreaByUnit, getUnitText } from '../../utils/getAreaByUnit';
import CircleOutlined from './../../public/add_circle_outline_24px.svg';
import CircleOutlinedDot from './../../public/dot.png';
import NewVersionCircleOutlined from './../../public/todays-new-field-version-icon.svg';
import { iff } from './../../utils/iff';
import { FontIcon } from './../common/iconComponent';
import { CopyFieldVersion } from './copyFieldVersion';
import './fieldHistoryCard.less';

/**
 * sort dates in descending order - latest version on top
 * @param dates
 * @returns
 */
export const getSortedDates = (dates: string[]) => {
  return dates.filter((a: any, b: any) => {
    const firstDate: any = new Date(a);
    const secondDate: any = new Date(b);
    return firstDate - secondDate;
  });
};

/**
 * when creating new version, find nearest version to that event date, to display fields basic info
 * @param updatedHistory
 * @param eventDate
 */
export const findClosestVersion = (updatedHistory: any, eventDate: string) => {
  let nearestVersion: any = {};
  let insertAtIndex = 0;
  const versionDates = updatedHistory.map((v: any) => v.event_date);
  const datesLessThanEventDate = versionDates.filter((d: string) =>
    moment(d).isBefore(moment(eventDate))
  );
  if (datesLessThanEventDate.length > 0) {
    const sortedDates = getSortedDates(datesLessThanEventDate);
    nearestVersion = updatedHistory.find((v: any, ind: number) => {
      if (moment(v.event_date).isSame(moment(sortedDates[0]))) {
        insertAtIndex = ind;
        return true;
      }
      return false;
    });
  } else {
    const datesGreaterThanEventDate = versionDates.filter((d: string) =>
      moment(d).isAfter(moment(eventDate))
    );
    if (datesGreaterThanEventDate.length > 0) {
      const sortedDates = getSortedDates(datesGreaterThanEventDate);
      insertAtIndex = updatedHistory.length;
      nearestVersion = updatedHistory.find((v: any) => {
        if (moment(v.event_date).isSame(moment(sortedDates[sortedDates.length - 1]))) {
          return true;
        }
        return false;
      });
    }
  }
  return { nearestVersion, insertAtIndex };
};

/**
 * if for selected event date version does not exist, then find the version and display it as new version
 * @param fieldHistoryById
 * @param eventDate
 * @returns
 */
export const addTodaysVersionInFieldHistory = (fieldHistoryById: any, eventDate: string) => {
  const updatedHistory = get(fieldHistoryById, 'history', []).map((v: any) => {
    v.isNewVersion = false;
    v.versionWithExactDate = false;
    return v;
  });
  const versionWithExactDate = updatedHistory.find((v: any) =>
    moment(v.event_date).isSame(moment(eventDate))
  );

  if (versionWithExactDate) {
    versionWithExactDate.versionWithExactDate = true;
  } else {
    const { insertAtIndex, nearestVersion } = findClosestVersion(updatedHistory, eventDate);

    const todaysVersion = Object.assign({}, nearestVersion);
    todaysVersion.event_date = moment(eventDate);
    todaysVersion.isNewVersion = true;
    todaysVersion.version_id = 999999;

    if (insertAtIndex === 0) {
      updatedHistory.unshift(todaysVersion);
    } else {
      updatedHistory.splice(insertAtIndex, 0, todaysVersion);
    }
  }

  return updatedHistory;
};

/**
 * when users clicks on plus icon to create new version, display this modal
 * @param props
 * @returns
 */
export const SelectNewVersionDateModal = (props: any) => {
  const {
    showSelectDateModal,
    setShowSelectDateModal,
    setShowLoaderForVersionChange,
    searchParams,
    setEventDate,
    changeDateClicked,
    setChangeDateClicked,
    t
  } = props;
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<any>('');

  const onSave = () => {
    setShowLoaderForVersionChange(true);
    setChangeDateClicked(false);
    searchParams.set('event_date', selectedDate);
    navigate({ search: searchParams.toString() }, { replace: true });
    setEventDate(selectedDate);
    setShowSelectDateModal(false);
  };

  return (
    <Modal
      visible={showSelectDateModal}
      onOk={onSave}
      onCancel={() => {
        setShowSelectDateModal(false);
        setChangeDateClicked(false);
      }}
      okText={t('common.button.save')}
      cancelText={t('common.button.cancel')}
      centered={true}
      closable={false}
      width={423}
      okType="primary"
      okButtonProps={{
        style: { background: '#14803C' },
        disabled: iff(selectedDate === '', true, false)
      }}
      className="select-version-date-modal"
      data-testid="select-version-date-modal"
    >
      <div
        className="select-version-date-modal-content"
        data-testid="select-version-date-modal-content"
      >
        <div
          className="select-version-date-modal-title"
          data-testid="select-version-date-modal-title"
        >
          {iff(
            changeDateClicked === true,
            t('global.map.field_history_card.change_date_for_this_version'),
            t('global.map.field_history_card.select_date_for_this_version')
          )}
        </div>
        <div data-testid="select-version-date-modal-description">
          <Row style={{ width: '100%' }}>
            <Col sm={24}>
              <Row>
                <Col className="label" sm={24} data-testid="select-version-date-modal-date-label">
                  {t('global.map.field_history_card.select_a_date')}
                </Col>
              </Row>
              <Row>
                <Col sm={24}>
                  <ConfigProvider locale={getLocale()}>
                    <DatePicker
                      className="date-picker"
                      allowClear={false}
                      placeholder={t('global.map.field_history_card.select_a_date')}
                      format={AppConstants.DATE_FORMATS.YYYY_DASH_MM_DASH_DD}
                      onChange={(event: any) => {
                        setSelectedDate(
                          moment(event).format(AppConstants.DATE_FORMATS.YYYY_DASH_MM_DASH_DD)
                        );
                      }}
                      data-testid="select-version-date-modal-date-input"
                    />
                  </ConfigProvider>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

/**
 * image to add new version (plus icon)
 * @param props
 * @returns
 */
export const AddNewVersion = (props: any) => {
  return (
    <img
      src={CircleOutlined}
      className={`timeline-circle-icon add-new-version-btn ${props.position}`}
      alt="timeline-circle-icon"
      onClick={() => props.setShowSelectDateModal(true)}
    />
  );
};

/**
 * when user clicks on a version, display that as selected version and update the content
 * @param props
 */
const onVersionClick = (props: any) => {
  const {
    clickedVersion,
    setEventDate,
    searchParams,
    setShowLoaderForVersionChange,
    setSelectedVersion,
    navigate
  } = props;

  setShowLoaderForVersionChange(true);
  searchParams.set('event_date', clickedVersion.event_date);
  /* history.replace changed to navigate */
  navigate({ search: searchParams.toString() }, { replace: true });
  setSelectedVersion(clickedVersion);
  setEventDate(clickedVersion.event_date);
};

/**
 * when user modifies some details, but does not save and click on another version
 * show this attension modal
 * @param props
 * @returns
 */
export const AttentionModal = (props: any) => {
  const { showAttension, setShowAttension, manageOnHistoryClick, clickedVersion, t } = props;
  return (
    <Modal
      visible={showAttension}
      onOk={() => {
        setShowAttension(false);
        manageOnHistoryClick({
          clickedVersion
        });
      }}
      onCancel={() => setShowAttension(false)}
      okText={t('global.map.field_history_card.continue')}
      cancelText={t('common.button.cancel')}
      centered={true}
      closable={false}
      width={423}
      okType="primary"
      okButtonProps={{
        style: { background: '#14803C' }
      }}
      className="attension-modal-confirm"
      data-testid="attension-modal-confirm"
    >
      <div
        className="attension-modal-confirm-content"
        data-testid="attension-modal-confirm-content"
      >
        <div className="left" data-testid="left">
          <ExclamationCircleOutlined style={{ color: '#0092E4' }} />
        </div>
        <div className="right" data-testid="right">
          <div className="attension-confirm-title" data-testid="attension-confirm-title">
            {t('global.map.field_history_card.attention')}
          </div>
          <div data-testid="attension-modal-description">
            {t('global.map.field_history_card.you_will_lose_the_edit')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * get version details to display
 * @param props
 * @returns
 */
export const VersionDetails = (props: any) => {
  const {
    version,
    unitSystem,
    currentVersion,
    isNewVersion,
    eventDate,
    isLastItem,
    versionClick,
    t
  } = props;
  return (
    <div className="text-content" onClick={() => versionClick(version)}>
      <div className="date-container">
        <span data-testid="event_date">
          {iff(
            isLastItem === true,
            t('global.map.field_history_card.creation'),
            moment(version.event_date).format(AppConstants.DATE_FORMATS.MMM_DD_YYYY)
          )}
        </span>
        {moment(currentVersion.event_date).isSame(moment(version.event_date)) && !isNewVersion && (
          <span>
            <Badge
              status="success"
              text={t('global.map.field_history_card.current')}
              className="current-version-badge"
              dot={false}
              color="#14803C"
            />
          </span>
        )}
        {isNewVersion && (
          <span className="todays-new-version-text">
            {t('global.map.field_history_card.new_version')}
          </span>
        )}
        {!isNewVersion && moment(eventDate).isSame(moment(version.event_date)) && (
          <span className="todays-new-version-text">
            {t('global.map.field_history_card.editing')}
          </span>
        )}
      </div>
      {!isNewVersion && (
        <>
          <div className="img-container">
            {iff(
              isEmpty(version.geometry),
              <div className="gemetry-noboundary-img">
                <FontIcon name="area-black" size={30} color="#C1C5C8" />
              </div>,
              <LazyLoad height={30} width={30}>
                <GeoJsonToSvg geojson={version} width="100%" height="100%" />
              </LazyLoad>
            )}
          </div>
          <div className="name-container">
            <span className="name">
              <Tooltip title={version.name}>
                <span>{version.name}</span>
              </Tooltip>
            </span>
            <span data-testid="version_area">{`${getAreaByUnit(
              version.declared_area,
              unitSystem
            )} ${getUnitText(unitSystem)}`}</span>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * edit and delete version action buttons
 * @param props
 * @returns
 */
export const VersionControlActions = (props: any) => {
  const {
    setShowPopover,
    t,
    versionClick,
    handleDeleteVersionClick,
    version,
    isNewVersion,
    setChangeDateClicked,
    setShowSelectDateModal,
    setShowCopyFieldVersionModal
  } = props;
  return (
    <div className="action-buttons">
      {iff(
        isNewVersion === true,
        <>
          <div
            className="actions-title"
            onClick={() => {
              setShowPopover(false);
              setChangeDateClicked(true);
              setShowSelectDateModal(true);
            }}
          >
            {t('common.text.change_date')}
          </div>
          <div
            className="actions-title"
            onClick={() => {
              setShowPopover(false);
              setShowCopyFieldVersionModal(true);
            }}
          >
            {t('global.map.field_history_card.copy_from_another_field')}
          </div>
        </>,
        <>
          <div
            className="actions-title"
            onClick={() => {
              setShowPopover(false);
              versionClick(version);
            }}
          >
            {t('global.map.field_history_card.edit_version')}
          </div>
          <div
            className="actions-title"
            onClick={() => {
              setShowPopover(false);
              handleDeleteVersionClick(version?.version_id, version?.event_date);
            }}
          >
            {t('global.map.field_history_card.delete_version')}
          </div>
        </>
      )}
    </div>
  );
};

/**
 * popover containing edit and delete version
 * @param props
 * @returns
 */
export const VersionControls = (props: any) => {
  const { setShowPopover, showPopOver, t } = props;

  return (
    <Popover
      content={VersionControlActions({ ...props, t })}
      placement={'topRight'}
      onOpenChange={() => {
        setShowPopover(!showPopOver);
      }}
      overlayClassName="versionControlPopover"
      trigger="click"
      open={showPopOver}
      data-testid="versionControlPopover"
    >
      <div
        className="more-button-container"
        onClick={() => {
          setShowPopover(!showPopOver);
        }}
        data-testid="more-button-container"
      >
        <img src={more_icon} alt="More" className="more-action" data-testid="more-action" />
      </div>
    </Popover>
  );
};

/**
 * single version details
 * @param props
 * @returns
 */
export const VersionInfo = (props: any) => {
  const {
    index,
    version,
    isLastItem,
    unitSystem,
    currentVersion,
    isDirty,
    setShowAttension,
    setClickedVersion,
    manageOnHistoryClick,
    eventDate,
    setShowSelectDateModal,
    setChangeDateClicked,
    setShowCopyFieldVersionModal,
    t
  } = props;

  const [showPopOver, setShowPopover] = useState(false);
  const isNewVersion = get(version, 'isNewVersion', false);
  const versionWithExactDate = get(version, 'versionWithExactDate', false);

  /**
   *
   * @param selectedVersion
   */
  const versionClick = (selectedVersion: any) => {
    if (!versionWithExactDate) {
      setClickedVersion(selectedVersion);
      if (isDirty) {
        setShowAttension(true);
      } else {
        manageOnHistoryClick({
          clickedVersion: selectedVersion
        });
      }
    }
  };

  return (
    <div
      className={`timeline-row ${iff(
        isNewVersion === true || versionWithExactDate === true,
        'new-version',
        ''
      )}`}
      key={index}
    >
      <div className="sp-1">
        <div className="top-img">
          <AddNewVersion setShowSelectDateModal={setShowSelectDateModal} />
        </div>
        <div className="content">
          <div className="line-div">
            <div className={`line ${iff(isLastItem === true, 'isLastItem', '')}`} />
            <div className="dot">
              <img
                src={iff(
                  isNewVersion === true || versionWithExactDate === true,
                  NewVersionCircleOutlined,
                  CircleOutlinedDot
                )}
                className="timeline-circle-icon"
                alt="timeline-circle-icon"
              />
            </div>
          </div>
          <VersionDetails
            versionClick={versionClick}
            version={version}
            unitSystem={unitSystem}
            currentVersion={currentVersion}
            isNewVersion={isNewVersion}
            eventDate={eventDate}
            isLastItem={isLastItem}
            t={t}
          />
          <VersionControls
            versionClick={versionClick}
            version={version}
            handleDeleteVersionClick={props.handleDeleteVersionClick}
            setShowPopover={setShowPopover}
            showPopOver={showPopOver}
            isNewVersion={isNewVersion}
            setChangeDateClicked={setChangeDateClicked}
            setShowSelectDateModal={setShowSelectDateModal}
            setShowCopyFieldVersionModal={setShowCopyFieldVersionModal}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * card title with close card icon
 * @param props
 * @returns
 */
const CardTitle = (props: any) => {
  return (
    <div className="timeline-container-title">
      <span>{props.t('global.map.field_history_card.card_title')}</span>
      <span
        className="timeline-container-title-close-btn"
        onClick={() => props.setShowFieldHistoryDetails(false)}
      >
        <FontIcon name="close-glyph" size={10.5} color="#696F88" />
      </span>
    </div>
  );
};

/**
 * main component
 * @param props
 * @returns
 */
export const FieldHistoryCard = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    fieldHistoryByIdLoading,
    fieldHistoryById,
    unitSystem,
    eventDate,
    setEventDate,
    setShowLoaderForVersionChange,
    setSelectedVersion,
    isDirty,
    setShowFieldHistoryDetails,
    dateFormat,
    t
  } = props;
  const [fieldHistory, setFieldHistory] = useState<any>([]);
  const searchParams = new URLSearchParams(location.search);
  const [showAttension, setShowAttension] = useState<boolean>(false);
  const [clickedVersion, setClickedVersion] = useState<any>({});
  const [showSelectDateModal, setShowSelectDateModal] = useState<boolean>(false);
  const [changeDateClicked, setChangeDateClicked] = useState<boolean>(false);
  const [showCopyFieldVersionModal, setShowCopyFieldVersionModal] = useState<boolean>(false);

  useEffect(() => {
    setFieldHistory(addTodaysVersionInFieldHistory(fieldHistoryById, eventDate));
  }, [eventDate, fieldHistoryById]);

  useEffect(() => {
    if (!Object.keys(clickedVersion).length && fieldHistory.length) {
      setClickedVersion(fieldHistory[0]);
    }
  }, [fieldHistory, clickedVersion]);

  const manageOnHistoryClick = (params: any) => {
    onVersionClick({
      navigate,
      clickedVersion: params.clickedVersion,
      setEventDate,
      searchParams,
      setShowLoaderForVersionChange,
      setSelectedVersion
    });
  };

  return (
    <>
      <Card
        title={<CardTitle t={t} setShowFieldHistoryDetails={setShowFieldHistoryDetails} />}
        bordered={false}
        data-testid="field_history_card"
      >
        {!fieldHistoryByIdLoading && fieldHistoryById.history.length > 0 && (
          <div className="timeline-container">
            {fieldHistory.map((version: any, index: number) => (
              <>
                <VersionInfo
                  t={t}
                  key={index}
                  index={index}
                  version={version}
                  unitSystem={unitSystem}
                  isLastItem={index === fieldHistory.length - 1}
                  currentVersion={get(fieldHistoryById, 'current', {})}
                  isDirty={isDirty}
                  setShowAttension={setShowAttension}
                  setClickedVersion={setClickedVersion}
                  clickedVersion={clickedVersion}
                  manageOnHistoryClick={manageOnHistoryClick}
                  handleDeleteVersionClick={props.handleDeleteVersionClick}
                  eventDate={eventDate}
                  setShowSelectDateModal={setShowSelectDateModal}
                  setChangeDateClicked={setChangeDateClicked}
                  setShowCopyFieldVersionModal={setShowCopyFieldVersionModal}
                />
                {checkTemporalGap(fieldHistory, index) && (
                  <ArchivedVersion
                    key={index + 'archived'}
                    setShowSelectDateModal={setShowSelectDateModal}
                    date={fieldHistory[index + 1]?.valid_until}
                  />
                )}
              </>
            ))}
          </div>
        )}
      </Card>
      {showAttension && (
        <AttentionModal
          showAttension={showAttension}
          t={t}
          setShowAttension={setShowAttension}
          manageOnHistoryClick={manageOnHistoryClick}
          clickedVersion={clickedVersion}
        />
      )}
      {showSelectDateModal && (
        <SelectNewVersionDateModal
          showSelectDateModal={showSelectDateModal}
          setShowSelectDateModal={setShowSelectDateModal}
          setShowLoaderForVersionChange={setShowLoaderForVersionChange}
          searchParams={searchParams}
          history={navigate}
          setEventDate={setEventDate}
          changeDateClicked={changeDateClicked}
          t={t}
          setChangeDateClicked={setChangeDateClicked}
        />
      )}
      {showCopyFieldVersionModal && (
        <CopyFieldVersion
          setShowCopyFieldVersionModal={setShowCopyFieldVersionModal}
          showCopyFieldVersionModal={showCopyFieldVersionModal}
          fieldHistory={fieldHistory}
          unitSystem={unitSystem}
          dateFormat={dateFormat}
          manageOnHistoryClick={manageOnHistoryClick}
          t={t}
          eventDate={eventDate}
          currentVersion={get(fieldHistoryById, 'current', {})}
        />
      )}
    </>
  );
};
