export const SET_ORGANIZATIONS_LIST_LOADING = 'SET_ORGANIZATIONS_LIST_LOADING';
export const SET_ORGANIZATIONS_LIST_SUCCESS = 'SET_ORGANIZATIONS_LIST_SUCCESS';
export const SET_ORGANIZATIONS_LIST_ERROR = 'SET_ORGANIZATIONS_LIST_ERROR';
export const SET_ALL_ORGANIZATIONS_LIST_LOADING = 'SET_ALL_ORGANIZATIONS_LIST_LOADING';
export const SET_ALL_ORGANIZATIONS_LIST_SUCCESS = 'SET_ALL_ORGANIZATIONS_LIST_SUCCESS';
export const SET_ALL_ORGANIZATIONS_LIST_ERROR = 'SET_ALL_ORGANIZATIONS_LIST_ERROR';
export const CREATE_ORG_LIST_SUCCESS = 'CREATE_ORG_LIST_SUCCESS';
export const CREATE_ORG_LIST_ERROR = 'CREATE_ORG_LIST_ERROR';
export const CREATE_ORG_LIST_LOADING = 'CREATE_ORG_LIST_LOADING';
export const DELETE_ORG_LIST_ITEM_RESET = 'DELETE_ORG_LIST_ITEM_RESET';
export const DELETE_ORG_LIST_ITEM_LOADING = 'DELETE_ORG_LIST_ITEM_LOADING';
export const DELETE_ORG_LIST_ITEM_SUCCESS = 'DELETE_ORG_LIST_ITEM_SUCCESS';
export const DELETE_ORG_LIST_ITEM_ERROR = 'DELETE_ORG_LIST_ITEM_ERROR';
export const EDIT_ORG_LIST_SUCCESS = 'EDIT_ORG_LIST_SUCCESS';
export const EDIT_ORG_LIST_ERROR = 'EDIT_ORG_LIST_ERROR';
export const EDIT_ORG_LIST_LOADING = 'EDIT_ORG_LIST_LOADING';
export const HIDE_SHOW_ALL_PRODUCTS_LOADING = 'HIDE_SHOW_ALL_PRODUCTS_LOADING';
export const HIDE_SHOW_ALL_PRODUCTS_SUCCESS = 'HIDE_SHOW_ALL_PRODUCTS_SUCCESS';
export const HIDE_SHOW_ALL_PRODUCTS_ERROR = 'HIDE_SHOW_ALL_PRODUCTS_ERROR';
export const RESET_ORG_LIST = 'RESET_ORG_LIST';
export const SET_PROPERTY_BY_ID_LOADING = 'SET_PROPERTY_BY_ID_LOADING';
export const SET_PROPERTY_BY_ID_SUCCESS = 'SET_PROPERTY_BY_ID_SUCCESS';
export const SET_PROPERTY_BY_ID_ERROR = 'SET_PROPERTY_BY_ID_ERROR';
export const RESET_PROPERTY_DETAILS = 'RESET_PROPERTY_DETAILS';
export const EDIT_PROPERTIES_BY_SEASONS_SUCCESS = 'EDIT_PROPERTIES_BY_SEASONS_SUCCESS';
export const SET_ORG_PROPERTIES_LOADING = 'SET_ORG_PROPERTIES_LOADING';
export const SET_ORG_PROPERTIES_SUCCESS = 'SET_ORG_PROPERTIES_SUCCESS';
export const SET_ORG_PROPERTIES_SUCCESS_PAGINATION = 'SET_ORG_PROPERTIES_SUCCESS_PAGINATION';
export const SET_ORG_PROPERTIES_ERROR = 'SET_ORG_PROPERTIES_ERROR';
export const SET_ORG_SEASONS_LOADING = 'SET_ORG_SEASONS_LOADING';
export const SET_ORG_SEASONS_SUCCESS = 'SET_ORG_SEASONS_SUCCESS';
export const SET_ORG_SEASONS_ERROR = 'SET_ORG_SEASONS_ERROR';
export const SET_SEASON_BY_PROPERTY = 'SET_SEASON_BY_PROPERTY';
export const SET_SEASON_BY_PROPERTY_LOADING = 'SET_SEASON_BY_PROPERTY_LOADING';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING = 'UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS = 'UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS';
export const UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR = 'UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR';
export const REMOVE_ORG_LIST_ITEM_AVATAR_LOADING = 'REMOVE_ORG_LIST_ITEM_AVATAR_LOADING';
export const REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS = 'REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS';
export const REMOVE_ORG_LIST_ITEM_AVATAR_ERROR = 'REMOVE_ORG_LIST_ITEM_AVATAR_ERROR';
export const SET_SEASON_FIELDS_LOADING = 'SET_SEASON_FIELDS_LOADING';
export const SET_SEASON_FIELDS_SUCCESS = 'SET_SEASON_FIELDS_SUCCESS';
export const SET_SEASON_FIELDS_ERROR = 'SET_SEASON_FIELDS_ERROR';
export const ADD_SEASON_FIELD_LOADING = 'ADD_SEASON_FIELD_LOADING';
export const ADD_SEASON_FIELD_SUCCESS = 'ADD_SEASON_FIELD_SUCCESS';
export const RESET_ADD_SEASON_FIELD = 'RESET_ADD_SEASON_FIELD';
export const EDIT_SEASON_FIELD_SUCCESS = 'EDIT_SEASON_FIELD_SUCCESS';
export const SET_EDIT_SEASON_SUCCESS = 'SET_EDIT_SEASON_SUCCESS';
export const ADD_SEASON_FIELD_ERROR = 'ADD_SEASON_FIELD_ERROR';
export const DELETE_SEASON_FIELD = 'DELETE_SEASON_FIELD';
export const SET_SEASON_BY_ID_SUCCESS = 'SET_SEASON_BY_ID_SUCCESS';
export const SET_SEASON_BY_ID_ERROR = 'SET_SEASON_BY_ID_ERROR';
export const SET_SEASON_BY_ID_LOADING = 'SET_SEASON_BY_ID_LOADING';
export const FETCH_PROPERTY_BY_ID_SUCCESS = 'FETCH_PROPERTY_BY_ID_SUCCESS';
export const FETCH_PROPERTY_BY_ID_LOADING = '  FETCH_PROPERTY_BY_ID_LOADING';
export const FETCH_PROPERTY_BY_ID_ERROR = '  FETCH_PROPERTY_BY_ID_ERROR';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';
export const FETCH_ORGANIZATIONS_LOADING = 'FETCH_ORGANIZATIONS_LOADING';
export const FETCH_ORGANIZATION_BY_ID_LOADING = 'FETCH_ORGANIZATION_BY_ID_LOADING';
export const FETCH_ORGANIZATION_BY_ID_SUCCESS = 'FETCH_ORGANIZATION_BY_ID_SUCCESS';
export const FETCH_ORGANIZATION_BY_ID_ERROR = 'FETCH_ORGANIZATION_BY_ID_ERROR';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const TOGGLE_DEEP_ACTION = 'TOGGLE_DEEP_ACTION';
export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const CREATE_ORG_LOADING = 'CREATE_ORG_LOADING';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_ERROR = 'CREATE_ORG_ERROR';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_ERROR = 'CREATE_PROPERTY_ERROR';
export const CREATE_PROPERTY_LOADING = 'CREATE_SIGNATURES_LOADING';
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS';
export const EDIT_PROPERTY_ERROR = 'EDIT_PROPERTY_ERROR';
export const SET_SELECTED_SEASONS = 'SET_SELECTED_SEASONS';
export const FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS = 'FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS';
export const FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING = 'FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING';
export const DELETE_PROPERTY_BY_ID_SUCCESS = 'DELETE_PROPERTY_BY_ID_SUCCESS';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const FETCH_USER_PROFILE_LOADING = 'FETCH_USER_PROFILE_LOADING';
export const FETCH_USER_WORKSPACE_SUCCESS = 'FETCH_USER_WORKSPACE_SUCCESS';
export const FETCH_CURRENT_WORKSPACE_SUCCESS = 'FETCH_CURRENT_WORKSPACE_SUCCESS';
export const FETCH_USER_WORKSPACE_LOADING = 'FETCH_USER_WORKSPACE_LOADING';
export const FETCH_USER_WORKSPACE_ERROR = 'FETCH_USER_WORKSPACE_ERROR';
export const DELETE_PROPERTY_BY_ID_ERROR = 'DELETE_PROPERTY_BY_ID_ERROR';
export const DELETE_PROPERTY_BY_ID_LOADING = 'DELETE_PROPERTY_BY_ID_LOADING';
export const DELETE_SEASON_BY_ID_SUCCESS = 'DELETE_SEASON_BY_ID_SUCCESS';
export const DELETE_SEASON_BY_ID_ERROR = 'DELETE_SEASON_BY_ID_ERROR';
export const DELETE_SEASON_BY_ID_LOADING = 'DELETE_SEASON_BY_ID_LOADING';
export const UPDATE_ORGANIZATION_LOADING = 'UPDATE_ORGANIZATION_LOADING';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_LOADING = 'DELETE_ORGANIZATION_LOADING';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_RESET = 'DELETE_ORGANIZATION_RESET';
export const UPLOAD_ORGANIZATION_AVATAR_LOADING = 'UPLOAD_ORGANIZATION_AVATAR_LOADING';
export const UPLOAD_ORGANIZATION_AVATAR_SUCCESS = 'UPLOAD_ORGANIZATION_AVATAR_SUCCESS';
export const UPLOAD_ORGANIZATION_AVATAR_ERROR = 'UPLOAD_ORGANIZATION_AVATAR_ERROR';
export const REMOVE_ORGANIZATION_AVATAR_LOADING = 'REMOVE_ORGANIZATION_AVATAR_LOADING';
export const REMOVE_ORGANIZATION_AVATAR_SUCCESS = 'REMOVE_ORGANIZATION_AVATAR_SUCCESS';
export const REMOVE_ORGANIZATION_AVATAR_ERROR = 'REMOVE_ORGANIZATION_AVATAR_ERROR';
export const FETCH_CROP_DETAILS_SUCCESS = 'FETCH_CROP_DETAILS_SUCCESS';
export const FETCH_CROP_DETAILS_LOADING = 'FETCH_CROP_DETAILS_LOADING';
export const FETCH_CROP_DETAILS_ERROR = 'FETCH_CROP_DETAILS_ERROR';
export const CREATE_SEASON_LOADING = 'CREATE_SEASON_LOADING';
export const CREATE_SEASON_SUCCESS = 'CREATE_SEASON_SUCCESS';
export const CREATE_SEASON_ERROR = 'CREATE_SEASON_ERROR';
export const RESET_CREATE_SEASON_FLAG = 'RESET_CREATE_SEASON_FLAG';
export const RESET_EDIT_SEASON_FLAG = 'RESET_EDIT_SEASON_FLAG';
export const EDIT_SEASON_ENQUEUED = 'EDIT_SEASON_ENQUEUED';
export const EDIT_SEASON_LOADING = 'EDIT_SEASON_LOADING';
export const EDIT_SEASON_SUCCESS = 'EDIT_SEASON_SUCCESS';
export const EDIT_SEASON_ERROR = 'EDIT_SEASON_ERROR';
export const ADD_MANAGED_USER_LOADING = 'ADD_MANAGED_USER_LOADING';
export const ADD_MANAGED_USER_SUCCESS = 'ADD_MANAGED_USER_SUCCESS';
export const ADD_MANAGED_USER_ERROR = 'ADD_MANAGED_USER_ERROR';
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';
export const GET_USER_DETAILS_BY_STATUS_LOADING = 'GET_USER_DETAILS_BY_STATUS_LOADING';
export const GET_USER_DETAILS_BY_STATUS_SUCCESS = 'GET_USER_DETAILS_BY_STATUS_SUCCESS';
export const GET_USER_DETAILS_BY_STATUS_ERROR = 'GET_USER_DETAILS_BY_STATUS_ERROR';
export const INVITE_USER_LOADING = 'INVITE_USER_LOADING';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const EDIT_USER_PERMISSION_LOADING = 'EDIT_USER_PERMISSION_LOADING';
export const EDIT_USER_PERMISSION_SUCCESS = 'EDIT_USER_PERMISSION_SUCCESS';
export const EDIT_USER_PERMISSION_ERROR = 'EDIT_USER_PERMISSION_ERROR';
export const EDIT_USER_DETAILS_LOADING = 'EDIT_USER_DETAILS_LOADING';
export const EDIT_USER_DETAILS_SUCCESS = 'EDIT_USER_DETAILS_SUCCESS';
export const EDIT_USER_DETAILS_ERROR = 'EDIT_USER_DETAILS_ERROR';
export const REMOVE_USER_LOADING = 'REMOVE_USER_LOADING';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';
export const FETCH_VARIETIES_LOADING = 'FETCH_VARIETIES_LOADING';
export const FETCH_VARIETIES_SUCCESS = 'FETCH_VARIETIES_SUCCESS';
export const FETCH_VARIETIES_ERROR = 'FETCH_VARIETIES_ERROR';
export const EDIT_ORG_LOADING = 'EDIT_ORG_LOADING';
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS';
export const EDIT_ORG_ERROR = 'EDIT_ORG_ERROR';
export const FETCH_ALL_FIELDS_BY_ORG_LOADING = 'FETCH_ALL_FIELDS_BY_ORG_LOADING';
export const FETCH_ALL_FIELDS_BY_ORG_ERROR = 'FETCH_ALL_FIELDS_BY_ORG_ERROR';
export const FETCH_FIELDS_BY_ID_LOADING = 'FETCH_FIELDS_BY_ID_LOADING';
export const FETCH_FIELDS_BY_ID_SUCCESS = 'FETCH_FIELDS_BY_ID_SUCCESS';
export const FETCH_FIELDS_BY_ID_ERROR = 'FETCH_FIELDS_BY_ID_ERROR';
export const RESET_ALL_FIELDS = 'RESET_ALL_FIELDS';
export const UPDATE_EXISTING_PROPERTY_IDS = 'UPDATE_EXISTING_PROPERTY_IDS';
export const FETCH_ALL_FIELDS_BY_ORG_SUCCESS = 'FETCH_ALL_FIELDS_BY_ORG_SUCCESS';
export const UPDATE_PROFILE_SETTINGS_LOADING = 'UPDATE_PROFILE_SETTINGS_LOADING';
export const UPDATE_PROFILE_SETTINGS_SUCCESS = 'UPDATE_PROFILE_SETTINGS_SUCCESS';
export const UPDATE_PROFILE_SETTINGS_ERROR = 'UPDATE_PROFILE_SETTINGS_ERROR';
export const FETCH_PROPERTIES_BY_SEASONS_LOADING = 'FETCH_PROPERTIES_BY_SEASONS_LOADING';
export const FETCH_PROPERTIES_BY_SEASONS_SUCCESS = 'FETCH_PROPERTIES_BY_SEASONS_SUCCESS';
export const FETCH_PROPERTIES_BY_SEASONS_ERROR = 'FETCH_PROPERTIES_BY_SEASONS_ERROR';
export const RESET_ADD_PROPERTIES_BY_SEASONS = 'RESET_ADD_PROPERTIES_BY_SEASONS';
export const ADD_PROPERTIES_BY_SEASONS_LOADING = 'ADD_PROPERTIES_BY_SEASONS_LOADING';
export const ADD_PROPERTIES_BY_SEASONS_SUCCESS = 'ADD_PROPERTIES_BY_SEASONS_SUCCESS';
export const ADD_PROPERTIES_BY_SEASONS_ERROR = 'ADD_PROPERTIES_BY_SEASONS_ERROR';
export const DELETE_PROPERTIES_BY_SEASONS = 'DELETE_PROPERTIES_BY_SEASONS';
export const FETCH_WORKSPACES_APPS_LOADING = 'FETCH_WORKSPACES_APPS_LOADING';
export const FETCH_WORKSPACES_APPS_SUCCESS = 'FETCH_WORKSPACES_APPS_SUCCESS';
export const FETCH_WORKSPACES_APPS_ERROR = 'FETCH_WORKSPACES_APPS_SUCCESS';
export const FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS =
  'FETCH_WORKSPACES_USER_IS_MEMBER_OF_SUCCESS';
export const FETCH_APPS_LOADING = 'FETCH_APPS_LOADING';
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
export const FETCH_APPS_ERROR = 'FETCH_APPS_ERROR';
export const RESET_FETCH_APPS = 'RESET_FETCH_APPS';
export const FETCH_APP_INFO_LOADING = 'FETCH_APP_INFO_LOADING';
export const FETCH_APP_INFO_SUCCESS = 'FETCH_APP_INFO_SUCCESS';
export const FETCH_APP_INFO_ERROR = 'FETCH_APP_INFO_ERROR';
export const FETCH_APP_CLIENTS_LOADING = 'FETCH_APP_CLIENTS_LOADING';
export const FETCH_APP_CLIENTS_SUCCESS = 'FETCH_APP_CLIENTS_SUCCESS';
export const FETCH_APP_CLIENTS_ERROR = 'FETCH_APP_CLIENTS_ERROR';
export const FETCH_EQUIPMENTS_DATA_LOADING = 'FETCH_EQUIPMENTS_DATA_LOADING';
export const FETCH_EQUIPMENTS_DATA_SUCCESS = 'FETCH_EQUIPMENTS_DATA_SUCCESS';
export const FETCH_EQUIPMENTS_DATA_ERROR = 'FETCH_EQUIPMENTS_DATA_ERROR';
export const RESET_EQUIPMENTS_DATA = 'RESET_EQUIPMENTS_DATA';
export const RESET_WORKERS_DATA = 'RESET_WORKERS_DATA';
export const FETCH_DETECT_FIELDS_BOUNDRIES_LOADING = 'FETCH_DETECT_FIELDS_BOUNDRIES_LOADING';
export const FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS = 'FETCH_DETECT_FIELDS_BOUNDRIES_SUCCESS';
export const FETCH_DETECT_FIELDS_BOUNDRIES_ERROR = 'FETCH_DETECT_FIELDS_BOUNDRIES_ERROR';
export const ADD_EQUIPMENT_LOADING = 'UPDATE_TABLE_LOADING';
export const ADD_EQUIPMENT_SUCCESS = 'UPDATE_TABLE_SUCCESS';
export const ADD_EQUIPMENT_ERROR = 'UPDATE_TABLE_ERROR';
export const EDIT_EQUIPMENT_LOADING = 'EDIT_EQUIPMENT_LOADING';
export const EDIT_EQUIPMENT_SUCCESS = 'EDIT_EQUIPMENT_SUCCESS';
export const EDIT_EQUIPMENT_ERROR = 'EDIT_EQUIPMENT_ERROR';
export const DELETE_EQUIPMENT_LOADING = 'DELETE_EQUIPMENT_LOADING';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_ERROR = 'DELETE_EQUIPMENT_ERROR';
export const RESET_EQUIPMENT_STORE = 'RESET_EQUIPMENT_STORE';
export const FETCH_EXECUTERS_DATA_LOADING = 'FETCH_EXECUTERS_DATA_LOADING';
export const FETCH_EXECUTERS_DATA_SUCCESS = 'FETCH_EXECUTERS_DATA_SUCCESS';
export const FETCH_WORKERS_DATA_SUCCESS = 'FETCH_WORKERS_DATA_SUCCESS';
export const FETCH_EXECUTERS_DATA_ERROR = 'FETCH_EXECUTERS_DATA_ERROR';
export const ADD_EXECUTERS_LOADING = 'ADD_EXECUTERS_LOADING';
export const ADD_EXECUTERS_SUCCESS = 'ADD_EXECUTERS_SUCCESS';
export const ADD_EXECUTERS_ERROR = 'ADD_EXECUTERS_ERROR';
export const EDIT_EXECUTERS_LOADING = 'EDIT_EXECUTERS_LOADING';
export const EDIT_EXECUTERS_SUCCESS = 'EDIT_EXECUTERS_SUCCESS';
export const EDIT_EXECUTERS_ERROR = 'EDIT_EXECUTERS_ERROR';
export const DELETE_EXECUTERS_LOADING = 'DELETE_EXECUTERS_LOADING';
export const DELETE_EXECUTERS_SUCCESS = 'DELETE_EXECUTERS_SUCCESS';
export const DELETE_EXECUTERS_ERROR = 'DELETE_EXECUTERS_ERROR';
export const RESET_EXECUTER_STORE = 'RESET_EXECUTER_STORE';
export const RESET_EXECUTER_DATA = 'RESET_EXECUTER_DATA';
export const FETCH_PRODUCTS_DATA_LOADING = 'FETCH_PRODUCTS_DATA_LOADING';
export const FETCH_PRODUCTS_DATA_SUCCESS = 'FETCH_PRODUCTS_DATA_SUCCESS';
export const FETCH_PRODUCTS_DATA_ERROR = 'FETCH_PRODUCTS_DATA_ERROR';
export const ADD_PRODUCTS_LOADING = 'ADD_PRODUCTS_LOADING';
export const ADD_PRODUCTS_SUCCESS = 'ADD_PRODUCTS_SUCCESS';
export const ADD_PRODUCTS_ERROR = 'ADD_PRODUCTS_ERROR';
export const EDIT_PRODUCTS_LOADING = 'EDIT_PRODUCTS_LOADING';
export const EDIT_PRODUCTS_SUCCESS = 'EDIT_PRODUCTS_SUCCESS';
export const EDIT_PRODUCTS_ERROR = 'EDIT_PRODUCTS_ERROR';
export const DELETE_PRODUCTS_LOADING = 'DELETE_PRODUCTS_LOADING';
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCTS_ERROR = 'DELETE_PRODUCTS_ERROR';
export const VISIBILITY_PRODUCTS_LOADING = 'VISIBILITY_PRODUCTS_LOADING';
export const VISIBILITY_PRODUCTS_SUCCESS = 'VISIBILITY_PRODUCTS_SUCCESS';
export const VISIBILITY_PRODUCTS_ERROR = 'VISIBILITY_PRODUCTS_ERROR';
export const RESET_PRODUCT_STORE = 'RESET_PRODUCT_STORE';
export const GET_TERMS_CONDITIONS_LOADING = 'GET_TERMS_CONDITIONS_LOADING';
export const GET_TERMS_CONDITIONS_SUCCESS = 'GET_TERMS_CONDITIONS_SUCCESS';
export const GET_TERMS_CONDITIONS_ERROR = 'GET_TERMS_CONDITIONS_ERROR';
export const GET_LICENSING_STATUS_LOADING = 'GET_LICENSING_STATUS_LOADING';
export const GET_LICENSING_STATUS_SUCCESS = 'GET_LICENSING_STATUS_SUCCESS';
export const GET_LICENSING_STATUS_ERROR = 'GET_LICENSING_STATUS_ERROR';
export const GET_LICENSING_STATUS_RESET = 'GET_LICENSING_STATUS_RESET';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS';
export const USER_LOGIN_REQUEST_ERROR = 'USER_LOGIN_REQUEST_ERROR';
export const SET_ACTIVE_SEASON_BY_ID_SUCCESS = ' SET_ACTIVE_SEASON_BY_ID_SUCCESS';
export const SET_ACTIVE_SEASON_BY_ID_LOADING = '  SET_ACTIVE_SEASON_BY_ID_LOADING';
export const SET_ACTIVE_SEASON_BY_ID_ERROR = '  SET_ACTIVE_SEASON_BY_ID_ERROR';
export const SET_REGULATORY_ZONE_LOADING = 'SET_REGULATORY_ZONE_LOADING';
export const SET_REGULATORY_ZONE_SUCCESS = 'SET_REGULATORY_ZONE_SUCCESS';
export const SET_REGULATORY_ZONE_ERROR = 'SET_REGULATORY_ZONE_ERROR';
export const SET_NAVBAR = 'SET_NAVBAR';
export const FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS = 'FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS';
export const FETCH_ALL_FIELDS_BY_PROP_ID_ERROR = 'FETCH_ALL_FIELDS_BY_PROP_ID_ERROR';
export const FETCH_ALL_FIELDS_BY_PROP_ID_LOADING = 'FETCH_ALL_FIELDS_BY_PROP_ID_LOADING';
export const FETCH_SEASONS_BY_PROP_ID_SUCCESS = 'FETCH_SEASONS_BY_PROP_ID_SUCCESS';
export const FETCH_SEASONS_BY_PROP_ID_ERROR = 'FETCH_SEASONS_BY_PROP_ID_ERROR';
export const FETCH_SEASONS_BY_PROP_ID_LOADING = 'FETCH_SEASONS_BY_PROP_ID_LOADING';
export const EDIT_SEASON_AFTER_SUCCESS = 'EDIT_SEASON_AFTER_SUCCESS';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const CREATE_REGION_ERROR = 'CREATE_REGION_ERROR';
export const REGION_LOADING = 'REGION_LOADING';
export const GET_REGION_TREE_BY_REGION_ID_LOADING = 'GET_REGION_TREE_BY_REGION_ID_LOADING';
export const GET_REGION_TREE_BY_REGION_ID_SUCCESS = 'GET_REGION_TREE_BY_REGION_ID_SUCCESS';
export const GET_REGION_TREE_BY_REGION_ID__ERROR = 'GET_REGION_TREE_BY_REGION_ID__ERROR';
export const FETCH_REGIONS_BY_PROPERTY_ID_LOADING = 'FETCH_REGIONS_BY_PROPERTY_ID_LOADING';
export const FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS = 'FETCH_REGIONS_BY_PROPERTY_ID_SUCCESS';
export const FETCH_REGIONS_BY_PROPERTY_ID_ERROR = 'FETCH_REGIONS_BY_PROPERTY_ID_ERROR';
export const RESET_REGION_LIST_DATA = 'RESET_REGION_LIST_DATA';
export const SETUP_ROLLOUT = 'SETUP_ROLLOUT';
export const SETUP_ROLLOUT_SUCCESS = 'SETUP_ROLLOUT_SUCCESS';
export const SETUP_ROLLOUT_ERROR = 'SETUP_ROLLOUT_ERROR';
export const GET_VOUCHER_REDEEM_INFO_LOADING = 'GET_VOUCHER_REDEEM_INFO_LOADING';
export const GET_VOUCHER_REDEEM_INFO_SUCCESS = 'GET_VOUCHER_REDEEM_INFO_SUCCESS';
export const GET_VOUCHER_REDEEM_INFO_ERROR = 'GET_VOUCHER_REDEEM_INFO_ERROR';
export const REDEEM_VOUCHER_LOADING = 'REDEEM_VOUCHER_LOADING';
export const REDEEM_VOUCHER_SUCCESS = 'REDEEM_VOUCHER_SUCCESS';
export const REDEEM_VOUCHER_ERROR = 'REDEEM_VOUCHER_ERROR';
export const FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING =
  'FETCH_FIELD_DETECTION_SOURCE_LIST_LOADING';
export const FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR = 'FETCH_FIELD_DETECTION_SOURCE_LIST_ERROR';
export const FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS =
  'FETCH_FIELD_DETECTION_SOURCE_LIST_SUCCESS';
export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS';
export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR';
export const FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING =
  'FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING';
export const CREATE_BILLING_INTENT_LOADING = 'CREATE_BILLING_INTENT_LOADING';
export const CREATE_BILLING_INTENT_SUCCESS = 'CREATE_BILLING_INTENT_SUCCESS';
export const CREATE_BILLING_INTENT_ERROR = 'CREATE_BILLING_INTENT_ERROR';
export const SET_WHATS_NEW_ISVISIBLE = 'SET_WHATS_NEW_ISVISIBLE';
export const HAVE_CONTRACT_FOR_ORG = 'HAVE_CONTRACT_FOR_ORG';
export const SET_CONTRACT_FOR_ORG = 'SET_CONTRACT_FOR_ORG';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING =
  'FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS =
  'FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR = 'FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR';
export const FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET = 'FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET';
export const GET_ACCOUNT_ROLES_LOADING = 'GET_ACCOUNT_ROLES_LOADING';
export const GET_ACCOUNT_ROLES_SUCCESS = 'GET_ACCOUNT_ROLES_SUCCESS';
export const GET_ACCOUNT_ROLES_ERROR = 'GET_ACCOUNT_ROLES_ERROR';
export const VERIFY_PERMISSIONS_LOADING = 'VERIFY_PERMISSIONS_LOADING';
export const VERIFY_PERMISSIONS_SUCCESS = 'VERIFY_PERMISSIONS_SUCCESS';
export const VERIFY_PERMISSIONS_ERROR = 'VERIFY_PERMISSIONS_ERROR';
export const EDIT_ACCOUNT_ROLES_LOADING = 'EDIT_ACCOUNT_ROLES_LOADING';
export const EDIT_ACCOUNT_ROLES_SUCCESS = 'EDIT_ACCOUNT_ROLES_SUCCESS';
export const EDIT_ACCOUNT_ROLES_ERROR = 'EDIT_ACCOUNT_ROLES_ERROR';
export const SET_EDIT_MEMBER_INVITATION_DATA = 'SET_EDIT_MEMBER_INVITATION_DATA';
export const SET_ORGANIZATIONS_BY_WORKSPACE_DATA = 'SET_ORGANIZATIONS_BY_WORKSPACE_DATA';
export const SET_PROPERTIES_BY_ORGANIZATIONS_DATA = 'SET_PROPERTIES_BY_ORGANIZATIONS_DATA';
export const SET_LOADING_ORGS_AND_PROPERTIES_DATA = 'SET_LOADING_ORGS_AND_PROPERTIES_DATA';
export const GET_ROLES_BY_CONTEXT_LOADING = 'GET_ROLES_BY_CONTEXT_LOADING';
export const GET_ROLES_BY_CONTEXT_SUCCESS = 'GET_ROLES_BY_CONTEXT_SUCCESS';
export const GET_ROLES_BY_CONTEXT_ERROR = 'GET_ROLES_BY_CONTEXT_ERROR';
export const GET_MULTIPLE_PROPS_REGIONS_LOADING = 'GET_MULTIPLE_PROPS_REGIONS_LOADING';
export const GET_MULTIPLE_PROPS_REGIONS_SUCCESS = 'GET_MULTIPLE_PROPS_REGIONS_SUCCESS';
export const GET_MULTIPLE_PROPS_REGIONS_ERROR = 'GET_MULTIPLE_PROPS_REGIONS_ERROR';
export const OPEN_USER_LOCATION_MODAL = 'OPEN_USER_LOCATION_MODAL';
export const CLOSE_USER_LOCATION_MODAL = 'CLOSE_USER_LOCATION_MODAL';
export const DELETE_ONLY_SEASON_BY_ID_SUCCESS = 'DELETE_ONLY_SEASON_BY_ID_SUCCESS';
export const DELETE_ONLY_SEASON_BY_ID_ERROR = 'DELETE_ONLY_SEASON_BY_ID_ERROR';
export const DELETE_ONLY_SEASON_BY_ID_LOADING = 'DELETE_ONLY_SEASON_BY_ID_LOADING';
export const SET_CONFIRMATION_MODEL_VISIBILITY = 'SET_CONFIRMATION_MODEL_VISIBILITY';
export const GET_CAMPAIGN_LINK_INFO_LOADING = 'GET_CAMPAIGN_LINK_INFO_LOADING';
export const GET_CAMPAIGN_LINK_INFO_SUCCESS = 'GET_CAMPAIGN_LINK_INFO_SUCCESS';
export const GET_CAMPAIGN_LINK_INFO_ERROR = 'GET_CAMPAIGN_LINK_INFO_ERROR';
export const REDEEM_CAMPAIGN_LINK_LOADING = 'REDEEM_CAMPAIGN_LINK_LOADING';
export const REDEEM_CAMPAIGN_LINK_SUCCESS = 'REDEEM_CAMPAIGN_LINK_SUCCESS';
export const REDEEM_CAMPAIGN_LINK_ERROR = 'REDEEM_CAMPAIGN_LINK_ERROR';
export const MEASURE_COORD_MODE_ACTIVE = 'MEASURE_COORD_MODE_ACTIVE';
export const FETCH_FIELD_DETECTION_TOWNSHIP_GRID_LOADING =
  'FETCH_FIELD_DETECTION_TOWNSHIP_GRID_LOADING';
export const FETCH_FIELD_DETECTION_TOWNSHIP_GRID_ERROR =
  'FETCH_FIELD_DETECTION_TOWNSHIP_GRID_ERROR';
export const FETCH_FIELD_DETECTION_TOWNSHIP_GRID_SUCCESS =
  'FETCH_FIELD_DETECTION_TOWNSHIP_GRID_SUCCESS';
export const SET_CANADA_TOWNSHIP_GRID_OFF_SUCCESS = 'SET_CANADA_TOWNSHIP_GRID_OFF_SUCCESS';
export const SET_MAPBOX_THEME = 'SET_MAPBOX_THEME';
export const SET_SHOW_MAPBOX_ROAD_NAMES = 'SET_SHOW_MAPBOX_ROAD_NAMES';
export const SET_SHOW_MAPBOX_FIELD_NAMES = 'SET_SHOW_MAPBOX_FIELD_NAMES';
export const SET_COPY_COORDINATES_MAPBOX = 'SET_COPY_COORDINATES_MAPBOX';
export const SET_GET_MEASUREMENTS_MAPBOX = 'SET_GET_MEASUREMENTS_MAPBOX';
export const SET_GET_FIELDS_COLORS_MAPBOX = 'SET_GET_FIELDS_COLORS_MAPBOX';
export const FETCH_SEASONS_BY_CYCLE_ID_SUCCESS = 'FETCH_SEASONS_BY_CYCLE_ID_SUCCESS';
export const FETCH_SEASONS_BY_CYCLE_ID_LOADING = 'FETCH_SEASONS_BY_CYCLE_ID_LOADING';
export const FETCH_SEASONS_BY_CYCLE_ID_ERROR = 'FETCH_SEASONS_BY_CYCLE_ID_ERROR';
export const FETCH_UNGROUPED_SEASONS_BY_PROPERTY_SUCCESS =
  'FETCH_UNGROUPED_SEASONS_BY_PROPERTY_SUCCESS';
export const FETCH_UNGROUPED_SEASONS_BY_PROPERTY_LOADING =
  'FETCH_UNGROUPED_SEASONS_BY_PROPERTY_LOADING';
export const FETCH_UNGROUPED_SEASONS_BY_PROPERTY_ERROR =
  'FETCH_UNGROUPED_SEASONS_BY_PROPERTY_ERROR';
export const RESET_SEASONS_BY_CYCLE_ID = 'RESET_SEASONS_BY_CYCLE_ID';
export const DELETE_CYCLE_BY_ID_SUCCESS = 'DELETE_CYCLE_BY_ID_SUCCESS';
export const DELETE_CYCLE_BY_ID_LOADING = 'DELETE_CYCLE_BY_ID_LOADING';
export const DELETE_CYCLE_BY_ID_ERROR = 'DELETE_CYCLE_BY_ID_ERROR';
export const CREATE_CYCLE_SUCCESS = 'CREATE_CYCLE_SUCCESS';
export const CREATE_CYCLE_LOADING = 'CREATE_CYCLE_LOADING';
export const CREATE_CYCLE_ERROR = 'CREATE_CYCLE_ERROR';
export const ATTACH_DETACH_SEASONS_TO_CYCLE_SUCCESS = 'ATTACH_DETACH_SEASONS_TO_CYCLE_LOADING';
export const ATTACH_DETACH_SEASONS_TO_CYCLE_LOADING = 'ATTACH_DETACH_SEASONS_TO_CYCLE_SUCCESS';
export const ATTACH_DETACH_SEASONS_TO_CYCLE_ERROR = 'ATTACH_DETACH_SEASONS_TO_CYCLE_ERROR';
export const UPDATE_CYCLE_SUCCESS = 'UPDATE_CYCLE_SUCCESS';
export const UPDATE_CYCLE_LOADING = 'UPDATE_CYCLE_LOADING';
export const UPDATE_CYCLE_ERROR = 'UPDATE_CYCLE_ERROR';
export const FETCH_PROXY_INVITATION_ROLES_SUCCESS = 'FETCH_PROXY_INVITATION_ROLES_SUCCESS';
export const FETCH_PROXY_INVITATION_ROLES_LOADING = 'FETCH_PROXY_INVITATION_ROLES_LOADING';
export const FETCH_PROXY_INVITATION_ROLES_ERROR = 'FETCH_PROXY_INVITATION_ROLES_ERROR';
export const FETCH_DISTRIBUTION_ACCESS_KEY_SUCCESS = 'FETCH_DISTRIBUTION_ACCESS_KEY_SUCCESS';
export const FETCH_DISTRIBUTION_ACCESS_KEY_LOADING = 'FETCH_DISTRIBUTION_ACCESS_KEY_LOADING';
export const FETCH_DISTRIBUTION_ACCESS_KEY_ERROR = 'FETCH_DISTRIBUTION_ACCESS_KEY_ERROR';
export const FETCH_PLAN_BY_ID_SUCCESS = 'FETCH_PLAN_BY_ID_SUCCESS';
export const FETCH_PLAN_BY_ID_LOADING = 'FETCH_PLAN_BY_ID_LOADING';
export const FETCH_PLAN_BY_ID_ERROR = 'FETCH_PLAN_BY_ID_ERROR';
export const REDEEM_DISTRIBUTION_ACCESS_KEY_SUCCESS = 'REDEEM_DISTRIBUTION_ACCESS_KEY_SUCCESS';
export const REDEEM_DISTRIBUTION_ACCESS_KEY_LOADING = 'REDEEM_DISTRIBUTION_ACCESS_KEY_LOADING';
export const REDEEM_DISTRIBUTION_ACCESS_KEY_ERROR = 'REDEEM_DISTRIBUTION_ACCESS_KEY_ERROR';
export const FETCH_CYCLE_BY_PROP_ID_ERROR = 'FETCH_CYCLE_BY_PROP_ID_ERROR';
export const FETCH_CYCLE_BY_PROP_ID_LOADING = 'FETCH_CYCLE_BY_PROP_ID_LOADING';
export const FETCH_CYCLE_BY_PROP_ID_SUCCESS = 'FETCH_CYCLE_BY_PROP_ID_SUCCESS';
export const SET_LAUNCHYDARKLY_FLAGS = 'SET_LAUNCHYDARKLY_FLAGS';
