import { useEffect, useState } from 'react';
import { ExclamationCircleTwoTone, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Modal } from 'syngenta-digital-cropwise-react-ui-kit';
import { Input } from 'antd';
import { FixedSizeList } from 'react-window';
import { debounced } from 'utils/custom-hooks/useDebounced';
import { translationWrapper } from '../../../utils/commonMethods';
import { iff } from '../../../utils/iff';

/**
 *
 * @param props
 * @returns
 */
export const ModalContent = (props: any) => {
  const { t } = translationWrapper(useTranslation());
  const { t: tWithoutNS } = translationWrapper(useTranslation(), false);
  const { totalAreaSelected, fieldLabels } = props;

  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [fields, setFields] = useState(totalAreaSelected);

  const filter = debounced(() => {
    setFields(() =>
      totalAreaSelected.filter(
        (field: { name: string }) =>
          field.name?.toLowerCase().includes(query.toLowerCase()) || !field.name
      )
    );

    setSearching(false);
  }, 1000);

  useEffect(() => {
    setQuery('');
    setFields(totalAreaSelected);
  }, [totalAreaSelected]);

  return (
    <div className="content">
      <span>{t(`field_list.text.the_following_${fieldLabels}`)}:</span>
      <Input
        style={{ marginTop: 12 }}
        placeholder={tWithoutNS('Search fields')}
        prefix={searching ? <LoadingOutlined /> : <SearchOutlined />}
        value={query}
        onChange={(ev) => {
          setSearching(true);
          setQuery(ev.target.value);
          filter();
        }}
      />
      <div style={{ margin: '12px 0' }}>
        <FixedSizeList
          height={Math.min(totalAreaSelected.length * 20, 500)}
          width={'100%'}
          itemCount={fields.length}
          itemSize={20}
        >
          {({ index, style }) => {
            const area = fields[index];
            return <span style={style}>&bull; {area.name}</span>;
          }}
        </FixedSizeList>
      </div>
      <p>{t('global.notification.field_delete_content')}</p>
    </div>
  );
};

/**
 *
 * @param props
 * @returns
 */
export const AllFieldsDeleteConfirmModal = (props: any) => {
  const {
    showAllFieldsDeleteConfirm,
    setShowAllFieldsDeleteConfirm,
    handleDeleteFields,
    deletingFields,
    setDeletingFields,
    totalAreaSelected,
    setSelectedFieldIds,
    fieldName
  } = props;
  const { t } = translationWrapper(useTranslation());
  const fieldLabels = iff(totalAreaSelected.length > 1, 'fields', 'field');
  return (
    <Modal
      open={showAllFieldsDeleteConfirm}
      onOk={() => handleDeleteFields()}
      onCancel={() => {
        setShowAllFieldsDeleteConfirm(false);
        setDeletingFields(false);
        setSelectedFieldIds([]);
      }}
      okText={t(
        fieldLabels === 'field' ? 'common.button.delete_field' : 'common.button.delete_fields'
      )}
      cancelText={t('common.button.cancel')}
      centered={true}
      closable={false}
      width={423}
      okType="danger"
      className="all-fields-delete-confirm-modal"
      okButtonProps={{ loading: deletingFields }}
      cancelButtonProps={{ disabled: deletingFields }}
    >
      <div className="all-fields-delete-confirm-modal-content">
        <div className="left">
          <ExclamationCircleTwoTone twoToneColor="#CF3537" style={{ fontSize: '20px' }} />
        </div>
        <div className="right">
          <div className="all-fields-delete-confirm-modal-title">
            {fieldName
              ? t('global.notification.field_delete_title', {
                  fieldName: totalAreaSelected[0].name
                })
              : t('global.notification.multiple_field_delete_title')}
          </div>
          <ModalContent totalAreaSelected={totalAreaSelected} fieldLabels={fieldLabels} />
          <p className="all-fields-delete-disclaimer">{t('common.button.delete_disclaimer')}</p>
        </div>
      </div>
    </Modal>
  );
};
